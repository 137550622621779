<template>
  <div v-if="error" class="bh-alert bh-alert-error">
    {{ error }}
    <button class="bh-alert-remove" v-on:click="RemoveAlert">X</button>
  </div>
  <h3 class="bh-text bh-text-left">Surveys > <strong>Create survey</strong></h3>
  <div class="row m-0">
    <div class="survey-questions">
      <div v-for="(question, i) in survey" :key="i" class="question-container">
        <div class="row">
          <div class="col-11">
            <!--Set question type-->
            <select v-model="question.type">
              <option value="title">Title</option>
              <option value="text">Text</option>
              <option value="number">Number</option>
              <option value="date">Date</option>
              <option value="range">Range</option>
              <option value="radio">Radio</option>
              <option value="checkbox">Check</option>
              <option value="select">Select</option></select
            ><br />

            <!--Set question description-->
            <input
              type="text"
              placeholder="Write your question"
              v-model="question.question"
            /><br />

            <!--Cast check-->
            <div v-if="question.type == 'radio' || question.type == 'checkbox'">
              <br />
              <label>Options</label><br />
              <div v-for="(element, index) in question.elements" :key="index">
                <div class="row">
                  <div class="col-11">
                    <input
                      type="text"
                      :placeholder="'Option ' + (index + 1)"
                      v-model="question.elements[index].value"
                    />
                  </div>
                  <div class="col-1 p-0">
                    <button
                      class="bh-button btn-remove"
                      v-on:click="RemoveElement(i, index)"
                    >
                      <img src="../../assets/img/icons/IconRemove.png" />
                    </button>
                  </div>
                </div>
              </div>
              <br />
              <button v-on:click="AddElement(i)" class="bh-button btn-sm">
                <img src="../../assets/img/add.png" />
              </button>
            </div>

            <!--Cast select-->
            <div v-if="question.type == 'select'">
              <br />
              <label>Options</label><br />
              <div v-for="(element, index) in question.elements" :key="index">
                <div class="row">
                  <div class="col-10">
                    <input
                      type="text"
                      :placeholder="'Option ' + (index + 1)"
                      v-model="question.elements[index].value"
                    />
                  </div>
                  <div class="col-2">
                    <button
                      class="bh-button btn-remove"
                      v-on:click="RemoveElement(question.elements, index)"
                    >
                      <img src="../../assets/img/icons/IconRemove.png" />
                    </button>
                  </div>
                </div>
              </div>
              <br />
              <button v-on:click="AddElement(i)" class="btn-sm">+</button>
            </div>
          </div>
          <div class="col-1 p-0">
            <button class="bh-button btn-remove" v-on:click="RemoveQuestion(i)">
              <img src="../../assets/img/icons/IconRemove.png" />
            </button>
          </div>
          <hr />
        </div>
      </div>
      <button class="bh-button bh-button-standard" v-on:click="AddQuestion">
        Add question
      </button>
    </div>
    <div class="survey-options">
      <div class="row">
        <div class="col-12">
          <div class="survey-banner">
            <img v-if="url" :src="url" />
          </div>
        </div>
        <div class="col-12">
          <p>Survey name</p>
          <input type="text" v-model="surveyName" placeholder="Survey Name" />
        </div>
        <div class="col-6">
          <p>Language</p>
          <select v-model="language">
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="pt">Portuguese</option>
          </select>
        </div>
        <div class="col-6">
          <p>Status</p>
          <select v-model="status" :class="status">
            <option value="Draft">Draft</option>
            <option value="Active">Active</option>
            <option value="Paused">Paused</option>
            <option value="Archived">Archived</option>
          </select>
        </div>
      </div>
      <button class="bh-button bh-button-full-width" v-on:click="AddSurvey">
        Submit survey
      </button>
    </div>
  </div>
</template>
<script>
import SurveyService from "@/services/SurveyService.js";

export default {
  data() {
    return {
      surveyName: "",
      language: "en",
      status: "Draft",
      survey: [],
      url: null,
      isEditing: false,
      hasNoQuestions: false,
      error: "",
    };
  },
  mounted() {
    this.GetSurvey();
  },
  methods: {
    GetSurvey() {
      let id = this.$route.params.id;
      if (id) {
        return SurveyService.GetSurveyById(id)
          .then(async ({ data }) => {
            this.surveyName = data.response.name;
            this.survey = data.response.inputs;
            this.isEditing = true;
          })
          .catch((e) => {
            this.error = e.response.data.response;
          });
      }
    },
    AddQuestion() {
      this.survey.push({
        question: "",
        type: "text",
        placeholder: "Answer",
        elements: [],
      });
    },
    RemoveQuestion(questionIndex) {
      this.survey.splice(questionIndex, 1);
    },
    AddElement(index) {
      this.survey[index].elements.push({ value: "" });
    },
    RemoveElement(questionIndex, elementIndex) {
      this.survey[questionIndex].elements.splice(elementIndex, 1);
    },
    AddSurvey() {
      let data = {
        name: this.surveyName,
        language: this.language,
        status: this.status,
        inputs: this.survey,
        rewards: [{ rewardType: 2, amount: 5 }],
      };

      if (this.survey.length > 0 && this.CheckForm()) {
        return SurveyService.AddSurvey(data)
          .then(async ({ data }) => {
            window.location.href = "/survey";
          })
          .catch((e) => {
            this.error = e.response.data.response;
          });
      } else {
        this.error = "Invalid or missing params";
      }
    },
    UpdateSurvey() {
      let data = {
        name: this.surveyName,
        inputs: this.survey,
      };

      return SurveyService.UpdateSurvey(data)
        .then(async ({ data }) => {
          window.location.href = "/survey";
        })
        .catch((e) => {
          this.error = e.response.data.response;
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    CheckForm() {
      this.error = "";

      this.survey.forEach((element) => {
        if (!element.question) this.error = "Invalid or missing params";
      });

      if (!this.surveyName) this.error = "Invalid or missing params";

      if (!this.error) return true;
      else return false;
    },
    RemoveAlert() {
      this.error = "";
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/Survey.css";
</style>
